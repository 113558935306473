import { WppButton } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useCreateBrandApi } from 'api/masterData/mutations/useCreateBrandApi'
import { useEditBrandApi } from 'api/masterData/mutations/useEditBrandApi'
import { useFetchBrandApi } from 'api/masterData/queries/useFetchBrandApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAliasesAutocomplete } from 'components/form/formAutocomplete/FormAliasesAutocomplete'
import { FormBrandsAutocomplete } from 'components/form/formAutocomplete/FormBrandsAutocomplete'
import { FormClientsAutocomplete } from 'components/form/formAutocomplete/FormClientsAutocomplete'
import { FormIndustriesAutocomplete } from 'components/form/formAutocomplete/FormIndustriesAutocomplete'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { useForm } from 'hooks/form/useForm'
import { showDeleteBrandModal } from 'pages/brands/deleteBrandModal/DeleteBrandModal'
import { convertToForm, convertFormToFormDto, defaultValues, useBrandValidationScheme } from 'pages/brands/upsert/utils'
import { handleReloadBrandsListTable } from 'pages/brands/utils'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { useToast } from 'providers/toast/ToastProvider'
import { BrandForm } from 'types/masterData/brands'
import { routesManager } from 'utils/routesManager'

export const UpsertBrand = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: handleCreate } = useCreateBrandApi()
  const { mutateAsync: handleEdit } = useEditBrandApi()

  const { entryId } = useParams()
  const { state } = useLocation()
  const brandState = state?.data

  const {
    data: brandData,
    isLoading,
    error,
  } = useFetchBrandApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const brand = brandData || brandState

  const form = useForm({
    defaultValues: convertToForm(brand) || defaultValues,
    validationSchema: useBrandValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: BrandForm) => {
    try {
      const { data: createdBrand } = await handleCreate({
        brand: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.brands.update.getURL({ entryId: createdBrand?.id }))

      await handleReloadBrandsListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.brand') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: BrandForm) => {
    try {
      await handleEdit({
        id: brand!.id,
        brand: convertFormToFormDto(values),
      })

      await handleReloadBrandsListTable(brand!.id)
      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.brand') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteBrandModal({
      brand,
      onAfterDelete: () => {
        navigate(routesManager.masterData.brands.root.getURL())
      },
    })
  }

  const ActionButtons = (
    <>
      {brand && (
        <WppButton variant="destructive-secondary" onClick={handleDelete}>
          {t('common.delete')}
        </WppButton>
      )}
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(brand ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        isLoading={isLoading}
        error={error}
        title={t(`common.${brand ? 'update' : 'create'}_entity`, { entity: t('common.master_data.entities.brand') })}
        actionButtons={ActionButtons}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload name={brandState?.name || ''} />
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormAliasesAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormClientsAutocomplete
            multiple={false}
            required
            labelConfig={{
              text: t('master_data.fields.client.label'),
              description: t('master_data.fields.client.hint'),
            }}
            name="clientId"
          />
          <FormBrandsAutocomplete
            name="parentId"
            multiple={false}
            labelConfig={{
              text: t('master_data.fields.parent.label', { entity: t('common.master_data.entities.brand') }),
              description: t('master_data.fields.parent.hint', {
                entity: t('common.master_data.entities.brand'),
                entities: t('common.master_data.entities.brand', { count: 2 }),
              }),
            }}
          />
          <FormIndustriesAutocomplete
            multiple
            labelConfig={{
              text: t('master_data.fields.industries.label'),
              description: t('master_data.fields.industries.hint'),
            }}
            name="industryIds"
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
