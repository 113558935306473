import { useCallback } from 'react'

import { useFetchClientsApi } from 'api/masterData/queries/useFetchClientsApi'
import { tableActions, TableInfiniteLoader } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableDefaults, TableKey } from 'constants/table'
import { useMasterDataAttachments } from 'hooks/attachments/useMasterDataAttachments'
import { queryClient } from 'providers/osQueryClient/utils'
import { Client } from 'types/masterData/clients'
import { excludeFalsy } from 'utils/common'

interface Params {
  search?: string
}

export const handleReloadClientsListTable = async (entryId?: string) => {
  await Promise.all([
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_CLIENTS] }),
    queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_CLIENT, { entryId }] }),
  ])
  tableActions.reload(TableKey.MASTER_DATA_CLIENTS)
}

export const useClientsLoader = ({ search }: Params) => {
  const handleFetchClientsList = useFetchClientsApi({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const { handleLoadAttachments, getAttachmentUrlByKey } = useMasterDataAttachments({
    staleTime: TableDefaults.LoaderStaleTime,
  })

  const loader: TableInfiniteLoader<Client> = useCallback(
    async ({ startRow, endRow }) => {
      const size = endRow - startRow

      const {
        data: {
          data,
          paginator: { totalItems },
        },
      } = await handleFetchClientsList({
        itemsPerPage: size,
        search,
        page: endRow / size,
      })

      const thumbnailKeys = data.map(({ logoThumbnail }) => logoThumbnail?.key).filter(excludeFalsy)
      await handleLoadAttachments(thumbnailKeys)

      return {
        data,
        totalRowsCount: totalItems,
      }
    },
    [search, handleFetchClientsList, handleLoadAttachments],
  )

  return { getAttachmentUrlByKey, loader }
}
