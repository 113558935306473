// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{background-color:var(--wpp-grey-color-100)}.RNK8S{width:180px}.Nv3Om{flex:1 1;min-height:300px;max-height:calc(100% - 24px);padding-bottom:24px}.W9y1O{display:flex !important}.Wqovt{order:3;height:100%}.b7HjV{order:1}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss","webpack://./src/pages/components/tablePageWrapper/TablePageWrapper.module.scss"],"names":[],"mappings":"AAAA,MACE,0CAAA,CCEF,OACE,WAAA,CAGF,OACE,QAAA,CACA,gBAAA,CACA,4BAAA,CACA,mBAAA,CAGF,OACE,uBAAA,CAGF,OACE,OAAA,CACA,WAAA,CAGF,OACE,OAAA","sourcesContent":[":root {\n  background-color: var(--wpp-grey-color-100);\n}\n\n@mixin containerWidthBoundaries {\n  width: 100%;\n  max-width: 1920px;\n  margin: 0 auto;\n}\n","@use 'src/app/App.module' as strictContainer;\n@use '@platform-ui-kit/components-library/dist/collection/scrollbar' as uiKit;\n\n.searchInput {\n  width: 180px;\n}\n\n.table {\n  flex: 1;\n  min-height: 300px;\n  max-height: calc(100% - 24px);\n  padding-bottom: 24px;\n}\n\n.moreMenu {\n  display: flex !important;\n}\n\n.pageCardRoot {\n  order: 3;\n  height: 100%;\n}\n\n.actionsContainer {\n  order: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": `RNK8S`,
	"table": `Nv3Om`,
	"moreMenu": `W9y1O`,
	"pageCardRoot": `Wqovt`,
	"actionsContainer": `b7HjV`
};
export default ___CSS_LOADER_EXPORT___;
