import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Brand, BrandForm, BrandFormDTO } from 'types/masterData/brands'
import { autocompleteOptionType, fileType } from 'utils/zod'

export const defaultValues: BrandForm = {
  name: '',
  aliases: [],
  clientId: [],
  industryIds: [],
  parentId: [],
  logoThumbnail: null,
  logoOriginal: null,
}

export const convertToForm = (values: Brand): BrandForm =>
  values && {
    ...values,
    clientId: values?.client ? [{ id: values.client.id, label: values.client.name }] : [],
    industryIds: (values.industries || []).map(item => ({ id: item.id, label: item.name })),
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
    parentId: values?.parent ? [{ id: values.parent.id, label: values.parent.name }] : [],
  }

export const convertFormToFormDto = (values: BrandForm): BrandFormDTO => ({
  ...values,
  aliases: values.aliases.map(item => item.id.toString()),
  clientId: values.clientId.length ? values.clientId[0].id.toString() : null,
  industryIds: values.industryIds.map(item => item.id.toString()),
  parentId: values.parentId.length ? values.parentId[0].id.toString() : null,
})

export const nameFieldCharacterMaxCount = 256
export const nameFieldCharacterMinCount = 1

export const useBrandValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        logoOriginal: fileType.nullable(),
        logoThumbnail: fileType.nullable(),
        name: zod
          .string()
          .trim()
          .min(
            nameFieldCharacterMinCount,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.brand'),
              characterCount: nameFieldCharacterMinCount,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.brand'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
        clientId: zod.union([
          zod.array(autocompleteOptionType).min(1, t('master_data.validation.client.required')),
          zod.string(),
        ]),
        industryIds: zod.array(zod.union([autocompleteOptionType, zod.string()])),
        aliases: zod.array(zod.union([autocompleteOptionType, zod.string()])),
        parentId: zod.union([zod.array(autocompleteOptionType), zod.string()]),
      }),
    [t],
  )
}
