import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { Market, MarketForm, MarketFormDTO } from 'types/masterData/markets'
import { uppercaseRegExp } from 'utils/regexp'
import { autocompleteOptionType } from 'utils/zod'

export const defaultValues: MarketForm = {
  name: '',
  aliases: [],
  isoAlpha2: '',
  isoAlpha3: '',
  logoThumbnail: null,
  logoOriginal: null,
  regionId: null,
}

export const convertToForm = (values: Market): MarketForm =>
  values && {
    ...values,
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
  }

export const convertFormToFormDto = (values: MarketForm): MarketFormDTO => ({
  ...values,
  aliases: values.aliases.map(item => item.id.toString()),
})

export const nameFieldCharacterMaxCount = 256
export const nameFieldCharacterMinCount = 2
export const isoAlpha2FieldCharacterCount = 2
export const isoAlpha3FieldCharacterCount = 3

export const useMarketValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        logoOriginal: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        logoThumbnail: zod
          .object({
            key: zod.string(),
            name: zod.string(),
            size: zod.number(),
          })
          .nullable(),
        name: zod
          .string()
          .trim()
          .min(
            nameFieldCharacterMinCount,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.market'),
              characterCount: nameFieldCharacterMinCount,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.market'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
        isoAlpha2: zod
          .string()
          .trim()
          .regex(uppercaseRegExp, t('master_data.validation.iso_alpha_2.uppercase'))
          .min(
            isoAlpha2FieldCharacterCount,
            t('master_data.validation.iso_alpha_2.length', {
              entity: t('common.master_data.entities.market'),
              characterCount: isoAlpha2FieldCharacterCount,
            }),
          )
          .max(
            isoAlpha2FieldCharacterCount,
            t('master_data.validation.iso_alpha_2.length', {
              entity: t('common.master_data.entities.market'),
              characterCount: isoAlpha2FieldCharacterCount,
            }),
          ),
        isoAlpha3: zod
          .string()
          .trim()
          .regex(uppercaseRegExp, t('master_data.validation.iso_alpha_3.uppercase'))
          .min(
            isoAlpha3FieldCharacterCount,
            t('master_data.validation.iso_alpha_3.length', {
              entity: t('common.master_data.entities.market'),
              characterCount: isoAlpha3FieldCharacterCount,
            }),
          )
          .max(
            isoAlpha3FieldCharacterCount,
            t('master_data.validation.iso_alpha_3.length', {
              entity: t('common.master_data.entities.market'),
              characterCount: isoAlpha3FieldCharacterCount,
            }),
          ),
        regionId: zod.string(),
        aliases: zod.array(zod.union([autocompleteOptionType, zod.string()])),
      }),
    [t],
  )
}
