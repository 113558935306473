import { WppButton } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useCreateMarketApi } from 'api/masterData/mutations/useCreateMarketApi'
import { useEditMarketApi } from 'api/masterData/mutations/useEditMarketApi'
import { useFetchMarketApi } from 'api/masterData/queries/useFetchMarketApi'
import { useRegionsApi } from 'api/masterData/queries/useRegionsApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAliasesAutocomplete } from 'components/form/formAutocomplete/FormAliasesAutocomplete'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { LoadingPage } from 'components/surface/loadingPage/LoadingPage'
import { useForm } from 'hooks/form/useForm'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { showDeleteMarketModal } from 'pages/markets/deleteMarketModal/DeleteMarketModal'
import {
  useMarketValidationScheme,
  defaultValues,
  convertFormToFormDto,
  convertToForm,
} from 'pages/markets/upsert/utils'
import { handleReloadMarketsListTable } from 'pages/markets/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { MarketForm } from 'types/masterData/markets'
import { routesManager } from 'utils/routesManager'

export const UpsertMarket = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: handleCreate } = useCreateMarketApi()
  const { mutateAsync: handleEdit } = useEditMarketApi()

  const { data: regions, isLoading: isRegionsLoading } = useRegionsApi()

  const { entryId } = useParams()
  const { state } = useLocation()
  const marketState = state?.data

  const {
    data: marketData,
    isLoading,
    error,
  } = useFetchMarketApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const market = marketData || marketState

  const form = useForm({
    defaultValues: convertToForm(market) || defaultValues,
    validationSchema: useMarketValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: MarketForm) => {
    try {
      const { data: createdMarket } = await handleCreate({
        market: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.markets.update.getURL({ entryId: createdMarket?.id }))

      await handleReloadMarketsListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.market') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: MarketForm) => {
    try {
      await handleEdit({
        id: market!.id,
        market: convertFormToFormDto(values),
      })

      await handleReloadMarketsListTable(market!.id)
      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.market') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteMarketModal({
      market,
      onAfterDelete: () => {
        navigate(routesManager.masterData.markets.root.getURL())
      },
    })
  }

  const ActionButtons = (
    <>
      {market && (
        <WppButton variant="destructive-secondary" onClick={handleDelete}>
          {t('common.delete')}
        </WppButton>
      )}
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(market ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )

  if (isRegionsLoading) return <LoadingPage />

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        isLoading={isLoading}
        error={error}
        title={t(`common.${market ? 'update' : 'create'}_entity`, { entity: t('common.master_data.entities.market') })}
        actionButtons={ActionButtons}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload name={marketState?.name || ''} />
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormAliasesAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormTextInput
            name="isoAlpha2"
            labelConfig={{
              text: t('master_data.fields.iso_alpha_2.label'),
            }}
            required
          />
          <FormTextInput
            name="isoAlpha3"
            labelConfig={{
              text: t('master_data.fields.iso_alpha_3.label'),
            }}
            required
          />
          <FormSelect
            name="regionId"
            labelConfig={{ text: t('master_data.fields.region.label') }}
            placeholder={t('master_data.fields.region.placeholder')}
            options={regions}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
