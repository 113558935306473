import { WppButton } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useCreateClientApi } from 'api/masterData/mutations/useCreateClientApi'
import { useEditClientApi } from 'api/masterData/mutations/useEditClientApi'
import { useFetchClientApi } from 'api/masterData/queries/useFetchClientApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAliasesAutocomplete } from 'components/form/formAutocomplete/FormAliasesAutocomplete'
import { FormClientsAutocomplete } from 'components/form/formAutocomplete/FormClientsAutocomplete'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { useForm } from 'hooks/form/useForm'
import { showDeleteClientModal } from 'pages/clients/deleteClientModal/DeleteClientModal'
import {
  useClientValidationScheme,
  convertToForm,
  convertFormToFormDto,
  defaultValues,
} from 'pages/clients/upsert/utils'
import { handleReloadClientsListTable } from 'pages/clients/utils'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { useToast } from 'providers/toast/ToastProvider'
import { ClientForm } from 'types/masterData/clients'
import { routesManager } from 'utils/routesManager'

export const UpsertClient = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: handleCreate } = useCreateClientApi()
  const { mutateAsync: handleEdit } = useEditClientApi()

  const { entryId } = useParams()
  const { state } = useLocation()
  const clientState = state?.data

  const {
    data: clientData,
    isLoading,
    error,
  } = useFetchClientApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const client = clientData || clientState

  const form = useForm({
    defaultValues: convertToForm(client) || defaultValues,
    validationSchema: useClientValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: ClientForm) => {
    try {
      const { data: createdClient } = await handleCreate({
        client: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.clients.update.getURL({ entryId: createdClient?.id }))

      await handleReloadClientsListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.client') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: ClientForm) => {
    try {
      await handleEdit({
        id: client!.id,
        client: convertFormToFormDto(values),
      })

      await handleReloadClientsListTable(client!.id)
      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.client') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteClientModal({
      client,
      onAfterDelete: () => {
        navigate(routesManager.masterData.clients.root.getURL())
      },
    })
  }

  const ActionButtons = (
    <>
      {client && (
        <WppButton variant="destructive-secondary" onClick={handleDelete}>
          {t('common.delete')}
        </WppButton>
      )}
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(client ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        isLoading={isLoading}
        error={error}
        title={t(`common.${client ? 'update' : 'create'}_entity`, { entity: t('common.master_data.entities.client') })}
        actionButtons={ActionButtons}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload name={clientState?.name || ''} />
          <FormTextInput
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormAliasesAutocomplete
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormClientsAutocomplete
            name="parentId"
            multiple={false}
            labelConfig={{
              text: t('master_data.fields.parent.label', { entity: t('common.master_data.entities.client') }),
              description: t('master_data.fields.parent.hint', {
                entity: t('common.master_data.entities.client'),
                entities: t('common.master_data.entities.client', { count: 2 }),
              }),
            }}
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
